<template>
  <div class="text-end">
    <!-- APPBAR -->
    <v-app-bar 
      absolute
      color="white"
      elevate-on-scroll
      app 
      elevation="1" light>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      
      <!-- Logo Left -->
      <v-row class="mt-4">
        <v-col cols="4" class="d-flex align-center" dense>
          <v-img v-if="true" alt="SADT" class="shrink mt-0 mb-4" src="../../assets/logo.png" width="60" @click="go('menu')"></v-img>
        </v-col>
        <v-col cols="5" class="text-right text-wrap text-truncate" @click="go('contas')" dense>
          <!--span class="grey--text">{{ per.usuario.papel.length > 0 ? config.usuario.papel[0].text :''}}</span-->
          <span >{{ permissoes.first_name }} {{ permissoes.last_name }}  </span>
        </v-col>
        <v-col cols="1" class="text-end">
          <v-avatar>
            <v-img  :src="permissoes.avatar" :alt="permissoes.first_name"></v-img>
          </v-avatar>     
        </v-col>
        <v-col cols="2" class="text-end">
          <Logout />
        </v-col>
      </v-row>
    </v-app-bar>

    <!-- DRAWER -->
    <v-navigation-drawer v-model="drawer" temporary fixed >
      <v-list dense nav>
        <v-list-item >
          <v-list-item-content>
            <v-img v-if="true" alt="SADT" class="shrink mt-0 mb-4" src="../../assets/logo.png" width="90" @click="go('menu')"></v-img>
          </v-list-item-content>
        </v-list-item>
        
      </v-list>
      <v-list dense nav>
        <v-list-item @click="go(menu.link)" link v-for="menu in items" :key="menu.id">
          <v-list-item-icon> 
            <v-icon>{{menu.icon}}</v-icon> 
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{menu.titulo}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
      </v-list>

    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '@/http'
// import AppConfigService from '../../service/AppConfigService'
export default {
  name: 'Drawer',
  components:{
    Logout:()=>import('../Logout.vue')
  },
  data: () => ({
    loading:false,
    initiallyOpen: [''],
    items:[],
    btn:true,
    tree: [],
    drawer:false,
    permissoes:{},
  }),
  computed:{
    ...mapGetters(['cor','mensagem','tokenAccess']),
    home(){
      return this.$route.name=='login'||this.$route.name=='home'||this.$route.name==undefined?false:true
    },
    breadcrumbs(){
      let path = this.$route.path
      let arr     = path.split('/')
      let tamanho = arr.length
      let caminho = []
      if (tamanho> 1){
        let c = '' 
        let t = ''
        for(let i=0;i < tamanho ;i++){
          c = `/${arr[i]}`
          t = i == 0 ? 'home' : `${arr[i]}`
          caminho.push({ text: t, disabled: (tamanho == i + 1), href: c })
        }
      }
      return caminho
      
    },
    menus(){
      return this.items
    },
    icons(){
      return []//AppConfigService.icons()
    },
  },
  methods:{
    go(val){
      if (this.$router.currentRoute.name !== val) {
        this.$router.push({ name: val });
      }
    },
    fechaLoading(){
      this.$store.dispatch('btnLoading',false)
      this.$store.dispatch('cor','')
      this.$store.dispatch('mensagem','')
    },
    get_apps(){
      this.loading = true
      api('modulos/listar/')
      .then(res =>{
        this.items = res.data
        console.log('items :>>> ', this.items)
        this.loading = false
      })
    },
    
    async get_permissoes(){
      this.loading = true
      const res = await api('contas/permissoes/')
      this.permissoes = res.data[0]||{}
      this.loading = false
    },
    
    
  },
  mounted(){
    this.get_apps();
    if(this.tokenAccess!== null){
      this.get_permissoes();
    }
  }
};

</script>